import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FiChevronRight } from "react-icons/fi"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const AboutPage = ({ data }) => {
  const {
    content,
    header_image,
    title,
    about_image_1,
    about_image_2,
    about_image_3,
    about_image_4,
  } = data.allPrismicAboutPage.edges[0].node.data
  return (
    <Layout>
      <SEO title="About" />
      <div className="about-page">
        <div className="about-page__image-container">
          <h1 className="about-page__title">{title.text}</h1>
          <Img fluid={header_image.fluid} />
        </div>
        <div className="about-page__content-container">
          <div className="about-page__content-container--col1">
            <h2>Our Company</h2>
            <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
          </div>
          <div className="about-page__content-container--col2">
            <div className="about-page__content-container--col2--image-container">
              <Img fluid={about_image_1.fluid} />
              <Img fluid={about_image_2.fluid} />
              <Img fluid={about_image_3.fluid} />
              <Img fluid={about_image_4.fluid} />
            </div>
          </div>
          <div className="about-page__content-container--row">
            <Link to="/quote" className="about-page__content-container--button">
              Get a quote now <FiChevronRight />
            </Link>
            <Link
              to="/services"
              className="about-page__content-container--button"
            >
              Learn about our services <FiChevronRight />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    allPrismicAboutPage {
      edges {
        node {
          data {
            content {
              html
            }
            header_image {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            title {
              text
            }
            about_image_1 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            about_image_2 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            about_image_3 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            about_image_4 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`
export default AboutPage
